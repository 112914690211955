import React, { useState, useEffect } from "react"

const Button = props => {
  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    //sloppy way to do this prop drilled way too much in this app
    //wait a second to reset submit this delay allows question to transition with button disabled
    //only becomes reenabled after transition
    
    //on load check if userAnswers is less than currentQuestion if so set submit to false
    setTimeout(() => {
      if (props.userAnswers.length < props.currentQuestion) {
        setSubmit(false)
      }
    }, 1000)
  }, [submit, props.userAnswers.length, props.currentQuestion])

  return (
    <button
      className={
        props.touched ? "quiz__button" : "quiz__button quiz__button__hide"
      }
      type="button"
      onClick={() => {
        if (!submit) {
          //if submit is false flip submit to true
          setSubmit(!submit)
          props.handleChange(props.questionText, props.answer)
        }

        //if submit is already true do nothing
        if (submit) return
      }}
      //if props.touched doesnt exist deafult to false
      disabled={!props.touched || false}
    >
      {props.text}
    </button>
  )
}

export default Button
