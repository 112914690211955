import React from "react"

const MultipleAnswers = props => {
  return props.answers.map((answer, index) => {
    const answerSubmit = [{
      text: answer.text,
      value: answer.value
    }];
    const questionText = props.question;
    return (
      <div key={index} className={`quiz__question__box__answer__${props.type}`}>
        <input
          className="quiz__hide"
          id={`${answer.id}-button`}
          name={props.question}
          checked={
            props.userAnswers !== undefined
              ? /* check if answerText exists in state */ props.userAnswers
                  .map(stateAnswer => stateAnswer.answerText)
                  .indexOf(answer.text) !== -1
              : false
          }
          onChange={() => {
            // console.log(props);
            // console.log(props.currentQuestion);
            // console.log(props.questionId);
            //ANIMATE HERE AND THEN SEND UP as callback or async HANDLECHANGE???
            if(props.currentQuestion === props.questionId) {
              console.log('trigger');
              props.handleChange(questionText, answerSubmit);
            }
          }}
          value={answer.value}
          type="radio"
        />
        <label
          className={`quiz__question__box__answer__${props.type}__label`}
          htmlFor={`${answer.id}-button`}
        >
          <p>{answer.text}</p>
        </label>
      </div>
    )
  })
}

export default MultipleAnswers
