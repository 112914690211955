import React from "react"
import { useSprings, animated } from "react-spring"

import MultipleAnswers from "./multipleanswers"
import SliderAnswers from "./slideranswers"
import MultipleOption from "./multipleoption"

//styling in quiz scss

const AnimatedQuestions = ({
  data,
  userAnswers,
  currentQuestion,
  handleChange,
}) => {

  const springs = useSprings(
    data.length,
    data.map((item, i) => ({
      to: [
        {
          // left:
          //   i + 1 === currentQuestion
          //     ? "50%"
          //     : i + 1 < currentQuestion
          //     ? "0%"
          //     : "60%",
          opacity: i + 1 === currentQuestion ? 1 : 0,
        },
        {
          display: i + 1 === currentQuestion ? "block" : "none",
        },
      ],
      from: {
        // position: "absolute",
        opacity: 0,
        // left: "60%",
        display: "block",
      },
    }))
  )

  return springs.map((props, index) => {
    const quesData = data[index]
    return (
      <animated.div
        key={quesData.id}
        style={props}
        className="quiz__question__box"
      >
        <h1 className="quiz__question__box__questionText">
          {quesData.question}
        </h1>

        <div
          className={`quiz__question__box__answer quiz__question__box__answer__${quesData.type}`}
        >
          {quesData.type === "yesNo" || quesData.type === "multipleChoice" ? (
            <MultipleAnswers
              answers={quesData.answers}
              userAnswers={userAnswers}
              handleChange={handleChange}
              question={quesData.question}
              type={quesData.type}
              questionId={quesData.id}
              currentQuestion={currentQuestion}
            />
          ) : quesData.type === "slider" ? (
            <SliderAnswers
              handleChange={handleChange}
              question={quesData.question}
              answers={quesData.answers}
              userAnswers={userAnswers}
              currentQuestion={currentQuestion}
            />
          ) : quesData.type === "multipleOption" ? (
            <MultipleOption
              answers={quesData.answers}
              userAnswers={userAnswers}
              handleChange={handleChange}
              question={quesData.question}
              type={quesData.type}
              currentQuestion={currentQuestion}
            />
          ) : (
            ""
          )}
        </div>
      </animated.div>
    )
  })
}

export default AnimatedQuestions
