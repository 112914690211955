import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import capitalize from "../../functions/capitalize"
import SectionLine from "../sectionline"
import Button from "../buttons/button"

const QuizResults = props => {
  //*********GET IMAGES */
  const {
    allFile: { edges: images },
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(png)/" }
          relativeDirectory: { eq: "quiz/icons" }
        }
      ) {
        edges {
          node {
            id
            base
            name
            childImageSharp {
              fluid {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `)

  //*********RESTRUCTURE USERANSWER CATEGORIES */
  const values = props.userAnswers.map(item =>
    item.answer.map(item => item.value)
  )
  let mergedValues = []
  values.forEach(array => {
    mergedValues.push(...array)
  })
  let counts = {}
  mergedValues.forEach(function (x) {
    counts[x] = (counts[x] || 0) + 1
  })

  let results = []
  let x
  for (x in counts) {
    if (counts[x] >= 1 || x === "nokava") {
      results.push(x)
    }
  }

  ///************GENERATE RESULTS BOXES */
  const returnResults = () => {
    return (
      <>
        {images.map((img, index) => {
          const name = img.node.name

          const exists = results.findIndex(result => result === name)

          if (exists > -1) {
            const imageKey = images.findIndex(img => img.node.name === name)
            return (
              <div className="quizresults__box__item" key={index}>
                <div className="quizresults__box__img">
                  <Img
                    fluid={images[imageKey].node.childImageSharp.fluid}
                    alt={name}
                  />
                </div>
                <h2 className="quizresults__box__text">{capitalize(name)}</h2>
              </div>
            )
          }

          return ""
        })}
      </>
    )
  }

  //*************MAIN JSX RETURN */
  return (
    <div className="u-standard-margins quizresults__container">
      <div className="quizresults__centercontainer">
        <div className="u-margin-bottom-medium quizresults__header">
          <h1 className="u-margin-bottom-tiny">Your Stress Relief Plan</h1>
          <div style={{ width: "50%" }}>
            <SectionLine />
          </div>
          <p className="u-margin-top-tiny">
            Your quiz suggests you would benefit most from supplements for:
          </p>
        </div>

        <div className="quizresults__box__container">{returnResults()}</div>
        <div className="quizresults__footer">
          <h1 className="u-margin-top-medium">
            Let's get you some supplements to help...
          </h1>
          
          <div className="u-margin-top-medium u-margin-bottom-big">

            <Button 
              text="Continue to Your Box" 
              type="primary" 
              link="yourbox"
              results={results}
            />
          </div>
          

        </div>
      </div>
    </div>
  )
}

export default QuizResults
