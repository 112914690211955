import React, { useState, useEffect } from "react"

import questionData from "../../data/quizquestions.json"

import Layout from "../components/layout"

import QuizForm from "../components/quiz/quizform"
import QuizResults from "../components/quiz/quizresults"
import QuizProgress from "../components/quiz/quizprogress"

const Quiz = () => {
  const [userAnswers, setUserAnswers] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [submit, setSubmit] = useState(false)
  const [prevQuestion, setPrevQuestion] = useState(1)

  //********************** GO BACK */
  //triggered by goback function submit (and exceeding num of questions)
  //triggered by currentQuestion change
  useEffect(() => {
    //if currentQuestion has been decremented
    if (currentQuestion <= prevQuestion) {
      //if currentQuestion # exists in userAnswers****
      const idExists = userAnswers
        .map(answer => answer.id)
        .indexOf(currentQuestion)
      //delete
      if (idExists >= 0) {
        //dont directly mutate state
        let answers = [...userAnswers]
        answers.pop()
        setUserAnswers([...answers])
      }
    }

    if (currentQuestion > questionData.length) {
      setSubmit(true)
    }

    setPrevQuestion(currentQuestion)

  }, [currentQuestion, prevQuestion, userAnswers])

  //********************** QUESTION SUBMIT */
  const handleChange = (questionText, answer) => {
    //1. get index of question id in userAnswers state if exists
    const idExists = userAnswers.map(a => a.id).indexOf(currentQuestion)
    //2. if doesnt exist then just add the new answer data to userAnswers state
    if (idExists === -1) {
      setUserAnswers([
        ...userAnswers,
        {
          id: currentQuestion,
          questionText,
          answer: answer,
        },
      ])
    }
    //3. if id already in state then replace with the new selection
    if (idExists >= 0) {
      let answersTemp = [...userAnswers]
      let answerTemp = { ...answersTemp[idExists] }
      answerTemp.text = answer.text
      answerTemp.value = answer.value
      answersTemp[idExists] = answerTemp
      setUserAnswers([...answersTemp])
    }
    //4. no matter what advance to next question with each change
    //putting conditionals based on this state change in callback to make sure state update completes before
    setCurrentQuestion(currentQuestion + 1)
  }

  //********************** ON BACK BUTTON CLICK */
  const goBack = () => {
    //putting conditionals based on this state change in callback to make sure state update completes before
    setCurrentQuestion(currentQuestion - 1)
    //useEffect will update userAnswers state if
    //currentQuestion has been decremented
  }

  //WAYYYY too much prop drilling below, started this project before learning react formally 
  //***FUTURE REFACTOR first thing change state management to avoid so much prop drilling redux or context api i suppose would be options */
  return (
    <Layout
      quizPage={true}
    >

    <section className="quiz">

      {/* FOR DEBUG MAKE SURE COMMENT OUT */}
      {/* <button onClick={() => console.log(userAnswers)}>check state</button> */}

      <div className={`quiz__${submit ? "mainSubmitted" : "main"}`}>
        {!submit ? (
          <QuizForm
            goBack={goBack} 
            questionData={questionData} 
            userAnswers={userAnswers}
            currentQuestion={currentQuestion}
            handleChange={handleChange}  
          />
        ) : (
          <QuizResults userAnswers={userAnswers} />
        )}
      </div>

      {submit ? (
        ""
      ) : (
        <QuizProgress
          currentQuestion={currentQuestion}
          totalQuestions={questionData.length}
        />
      )}
    </section>
    </Layout>
  )
}

export default Quiz
