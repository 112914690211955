import React, { useState } from "react"
// import { makeStyles } from "@material-ui/core/styles"
// import Typography from "@material-ui/core/Typography"
import Slider from "@material-ui/core/Slider"

import QuizButton from "./quizbutton"

const SliderAnswers = (props) => {
    //has user engaged ui
    const [touched, setTouched] = useState(false);

    //get default values for state
    const defaultSliderText = 5;
    const defaultSelection = [{text: 5, value: "none"}];
    
    const [selection, setSelection] = useState(defaultSelection);
    

    const marks = [
        {
            value: 0,
            label: 'Low'
        },
        {
            value: 5,
            label: 'Average'
        },
        {
            value: 10,
            label: 'High'
        }
    ]

    const getAnswer = (text) => {
        const index = props.answers.findIndex( element => element.text === text ? true : false );
        const value = props.answers[index].value;

        setSelection([{text, value}]);
        
    }

    function valuetext(value) {
        return value;
      }

    return (
        <div className="quiz__slider__container">
            <Slider
                className="u-margin-bottom-big u-margin-top-medium quiz__slider"
                defaultValue={defaultSliderText}
                step={1}
                min={0}
                max={10}
                valueLabelDisplay="auto"
                value={selection.text}
                marks={marks}
                getAriaValueText={valuetext}
                onChange={(e, value) => {
                    setTouched(true);
                    //trigger function that grabs value
                    //looks up accompanying text
                    //updates a state that is {text, value}
                    // setSliderText(value);
                    getAnswer(value);
                }}
            />


            <QuizButton 
                text="Continue"
                handleChange={props.handleChange}
                answer={selection}
                questionText={props.question}
                touched={touched}
                userAnswers={props.userAnswers}
                currentQuestion={props.currentQuestion}
            />

        </div>
    )
}

export default SliderAnswers