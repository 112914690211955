import React from "react"

import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import AnimatedQuestions from "./animatedquestions.js"

const QuizForm = ({
  goBack,
  questionData,
  userAnswers,
  currentQuestion,
  handleChange,
}) => {
  return (
    <form className="quiz__question__container" onSubmit={e => console.log(e)}>
      <button
        className="quiz__link quiz__back"
        type="button"
        onClick={currentQuestion === 1 ? () => navigate("/") : () => goBack()}
      >
        <FontAwesomeIcon icon={faChevronLeft} title="Back left arrow" />
        &nbsp;Back
      </button>
      <AnimatedQuestions
        data={questionData}
        userAnswers={userAnswers}
        currentQuestion={currentQuestion}
        handleChange={handleChange}
      />
    </form>
  )
}

export default QuizForm
