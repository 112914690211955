import React, { useState, useEffect } from "react"
import QuizButton from "./quizbutton"

const MultipleOption = props => {
  const [selection, setSelection] = useState([])
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (selection.length > 0) setTouched(true)
    if (selection.length === 0) setTouched(false)
  }, [selection])

  const handleChange = (text, value) => {

    //is none already in state
    let noneExists = selection.findIndex(element =>
      element.text === "None of the above" ? true : false
    )
    //is current selection already in state
    let existsIndex = selection.findIndex(element =>
      element.text === text ? true : false
    )

    //if not in state already
    if (existsIndex === -1) {

      //if selection is none, set that only
      if (text === "None of the above") {
        setSelection([{ text, value }])
      } else {
        //otherwise delete none if it is already in state
        if (noneExists >= 0) {
          let tempSelection = [...selection]
          tempSelection.splice(noneExists, 1)
          setSelection([...tempSelection, { text, value }])
        }
        //or if none is not in state already just add new selection to it
        if (noneExists === -1) {
          setSelection([...selection, { text, value }])
        }
      }
    }

    //if the selection is already in the state remove it on click
    if (existsIndex >= 0) {
      let tempSelection = [...selection]
      tempSelection.splice(existsIndex, 1)
      setSelection([...tempSelection])
    }
  }

  return (
    <>
      {props.answers.map((answer, index) => {
        return (
          <div
            key={index}
            className={`quiz__question__box__answer__${props.type}`}
          >
            <input
              className="quiz__hide"
              id={`${answer.id}-button`}
              name={props.question}
              data-answer-text={answer.text}
              checked={
                selection.findIndex(element => element.text === answer.text) >=
                0
              }
              onChange={() => handleChange(answer.text, answer.value)}
              value={answer.value}
              type="checkbox"
            />
            <label
              className={`quiz__question__box__answer__${props.type}__label`}
              htmlFor={`${answer.id}-button`}
            >
              <p>{answer.text}</p>
            </label>
          </div>
        )
      })}

      {/* FOR DEBUGGGG DELETE ONCE DONE
      <button
        type="button"
        onClick={() => {
          console.log(selection)
          console.log(selection.length)
        }}
      >
        PRINT STATE
      </button> */}

      <QuizButton
        text="Continue"
        handleChange={props.handleChange}
        answer={selection}
        questionText={props.question}
        touched={touched}
        userAnswers={props.userAnswers}
        currentQuestion={props.currentQuestion}
      />
    </>
  )
}

export default MultipleOption
