import React from "react"
import { useSpring, animated } from "react-spring"
import useMeasure from "./useMeasure"

const QuizProgress = ({ currentQuestion, totalQuestions }) => {
  const [bind, { width }] = useMeasure()
  const props = useSpring({
    width: currentQuestion > 1 ? (((currentQuestion-1)/totalQuestions) * width) : 0,
    // backgroundColor: open ? "hotpink" : "turquoise",
    config: { duration: 1000 },
  })  

  return (
    <div {...bind} className="quiz__progressBar__main">
    <animated.div className="quiz__progressBar__fill" style={props} />
    <animated.div className="quiz__progressBar__text">
        {(currentQuestion-1)}/{totalQuestions}
      {/* {props.width.interpolate(x => Math.floor((x.toFixed(0) * 100) / width))} */}
    </animated.div>
  </div>
  )
}

export default QuizProgress
